import React, { CSSProperties } from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { useChallengeData } from '../../../../../../../contexts/storage-contexts/Challenge';
import { useSettings } from '@wix/tpa-settings/react';
import { getDurationForFlexyTimelines } from '../../../../../../../selectors/challenges';
import challengeSettings from '../../../../../settingsParams';
import {
  FCWithChildren,
  serverTimelineTypeToClientTimelineString,
} from '@wix/challenges-web-library';
import utils from '../../../../../../ParticipantPage/Widget/views/utils';
import { RewardInfo } from '../../../../../../../components-shared/RewardInfo';
import { DurationUnit } from '@wix/ambassador-challenges-v1-participant/types';
import { dataHooks } from '@wix/data-hooks';
import { JoinButtonIds } from '../sections/VisitorPageJoinButton';

import { VisitorPageJoinButtonBox } from '../sections';
import { st, classes } from './VisitorPageHeader.st.css';
import {
  useCSSPBEnabled,
  useCSSPBStyle,
} from '../../../../../../../hooks/useCSSPBStyle';

export const visitorPageHeaderDataHooks = dataHooks<{
  main;
  title;
  detailsItem: { name: string };
}>('visitor-page-header');

interface IVisitorPageHeaderProps {
  className?: string;
}

export const VisitorPageHeader: FCWithChildren<IVisitorPageHeaderProps> = ({
  className,
}) => {
  const { t } = useTranslation();
  const { isMobile, language } = useEnvironment();
  const settings = useSettings();
  const cssPBEnabled = useCSSPBEnabled();
  const {
    challengeData: { challenge },
  } = useChallengeData();

  const { showHeaderDate } = utils.getBaseViewOptionsForVisitor(
    challenge,
    settings,
  );

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge.settings.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days_icu`,
    `challenge-card.duration-string.flexible.weeks_icu`,
    'challenge-card.duration-string.no-limit',
  );
  const getCSSPBStyle = useCSSPBStyle();
  const showRewards =
    cssPBEnabled || settings.get(challengeSettings.displayRewardsForVisitor);
  const showTopJoin =
    cssPBEnabled || settings.get(challengeSettings.displayTopJoinButton);
  return (
    <header
      className={st(
        classes.root,
        {
          mobile: isMobile,
          alignment: settings.get(challengeSettings.headerTextAlignment),
        },
        className,
      )}
      data-hook={visitorPageHeaderDataHooks.main()}
    >
      <div className={classes.headerInner}>
        {showHeaderDate ? (
          <p className={classes.headerDateInfo}>{durationString}</p>
        ) : null}

        <h1
          className={classes.headerTitle}
          data-hook={visitorPageHeaderDataHooks.title()}
        >
          {(challenge as any)?.shouldTranslateTitle
            ? t(challenge.settings?.description?.title)
            : challenge.settings?.description?.title}
        </h1>

        <VisitorPageHeaderOverview challenge={challenge} />

        {showRewards ? (
          <RewardInfo
            className={classes.rewards}
            rewards={challenge.settings.rewards}
            style={getCSSPBStyle({
              displayVar: '--opgDisplayRewardsForVisitor',
            })}
          />
        ) : null}

        {showTopJoin ? (
          <VisitorPageJoinButtonBox
            buttonId={JoinButtonIds.Top}
            alignment={settings.get(challengeSettings.headerTextAlignment)}
            style={getCSSPBStyle({ displayVar: '--opgDisplayTopJoinButton' })}
          />
        ) : null}
      </div>
    </header>
  );
};

interface IVisitorPageHeaderOverview {
  challenge: Challenge;
}

const VisitorPageHeaderOverview: FCWithChildren<IVisitorPageHeaderOverview> =
  function ({ challenge }) {
    const { t } = useTranslation();
    const settings = useSettings();
    const cssPBEnabled = useCSSPBEnabled();
    const {
      showOverview,
      showDuration,
      showParticipants,
      showSteps,
      overviewItemsCount,
      stepsCount,
    } = utils.getBaseViewOptionsForVisitor(challenge, settings, cssPBEnabled);

    const duration = getDurationForFlexyTimelines(challenge);

    const style: CSSProperties = {
      gridTemplateColumns: `repeat(${overviewItemsCount}, 135px)`,
    };
    return showOverview ? (
      <ul
        style={style}
        className={st(
          classes.detailsList,
          cssPBEnabled ? classes.opgDetailsList : undefined,
        )}
      >
        {showDuration ? (
          <VisitorPageHeaderDetailsItem
            value={duration?.value}
            description={
              duration?.unit === DurationUnit.DAYS
                ? t(`challenge.page.details.days_icu`, {
                    count: duration?.value,
                  })
                : t(`challenge.page.details.weeks_icu`, {
                    count: duration?.value,
                  })
            }
            dataHook={visitorPageHeaderDataHooks.detailsItem({
              name: 'duration',
            })}
            className={
              cssPBEnabled ? classes.opgDisplayChallengeDuration : undefined
            }
          />
        ) : null}

        {showSteps ? (
          <VisitorPageHeaderDetailsItem
            value={stepsCount}
            description={t(`challenge.page.details.steps_icu`, {
              count: stepsCount,
            })}
            dataHook={visitorPageHeaderDataHooks.detailsItem({
              name: 'steps',
            })}
            className={
              cssPBEnabled ? classes.opgDisplayChallengeSteps : undefined
            }
          />
        ) : null}

        {showParticipants ? (
          <VisitorPageHeaderDetailsItem
            value={challenge.participantsSummary.participantsNumber}
            description={t(`challenge.page.details.participants_icu`, {
              count: challenge.participantsSummary.participantsNumber,
            })}
            dataHook={visitorPageHeaderDataHooks.detailsItem({
              name: 'participants',
            })}
            className={
              cssPBEnabled ? classes.opgDisplayChallengeParticipants : undefined
            }
          />
        ) : null}
      </ul>
    ) : null;
  };

interface IVisitorPageHeaderDetailsItem
  extends Omit<React.LiHTMLAttributes<HTMLLIElement>, 'value'> {
  value: string | number;
  description: string;
  dataHook?: string;
}

const VisitorPageHeaderDetailsItem: FCWithChildren<IVisitorPageHeaderDetailsItem> =
  function ({ value, description, dataHook, className }) {
    const settings = useSettings();
    return (
      <li
        className={st(
          classes.detailsListItem,
          !settings.get(challengeSettings.displayDivider) &&
            classes.detailsListItemNoDivider,
          className,
        )}
        data-hook={dataHook}
      >
        <span className={classes.detailsListItemValue}>{value}</span>
        <span className={classes.detailsListItemDescription}>
          {description.split(' ')[1]}
        </span>
      </li>
    );
  };
