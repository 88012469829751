import { useControllerData } from '../../main/controllerContext';
import { IVideoContext } from './interfaces';

export const useVideo = (): IVideoContext => {
  const props = useControllerData();

  return {
    requestVideoData: props.requestVideoData,
    videoData: props.videoData,
    isVideoRequestInProgress: props.isVideoRequestInProgress,
    videoDataRequestError: props.videoDataRequestError,
    requestHLSStreamURL: props.requestHLSStreamURL,
    HLSStreamURL: props.HLSStreamURL,
    isHLSStreamURLRequestInProgress: props.isHLSStreamURLRequestInProgress,
    HLSStreamURLRequestError: props.HLSStreamURLRequestError,
    resetVideoState: props.resetVideoState,
  };
};
