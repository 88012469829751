import React from 'react';

import { Button } from 'wix-ui-tpa/cssVars';
import { ActionBlockerAppearance } from '../../../../../../../../components-shared/ActionBlocker/ActionBlocker';
import { PreviewActionBlockerContainer } from '../../../../../../../../components-shared/ActionBlocker/PreviewActionBlockerContainer';

import {
  UserState,
  useUser,
} from '../../../../../../../../contexts/User/UserContext';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useChallengeData } from '../../../../../../../../contexts/storage-contexts/Challenge';
import joinValidation, { IJoinValidationAction } from './joinValidation';
import { TextAlignment } from '../../../../../../Settings/challengeSettings/challengeSettings.types';
import { useSettingsEvents } from '../../../../../../../../contexts/SettingsEvents/SettingsEvents';
import { useVisitorPageBase } from '../../../contexts/VisitorPageBase/VisitorPageBaseContext';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../../../../settingsParams';
import { isNeedToSelectStartDate } from '../../../../../../../../selectors/challenges';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';

import { scrollToElement } from '../../../../../../../../services/scrollHelpers';
import { getJoinButtonText } from './helpers/getJoinButtonText';
import { getJoinBIButtonName } from './helpers/getJoinBIButtonName';

import { getJoinClickHandler } from './helpers/getJoinClickHandler';
import { isAvailableForJoinImmediately } from '../../../../../../../../selectors/isAvailableForJoinImmediately';

import { FCWithChildren } from '@wix/challenges-web-library';
import { st, classes } from './VisitorPageJoinButton.st.css';
import { useMemberPaidPlans } from '../../../../../../../../contexts/storage-contexts/PaidPlans';

export enum JoinButtonIds {
  Top = 'Top',
  Bottom = 'Bottom',
  InsideSection = 'InsideSection',
}

interface IVisitorPageJoinButtonProps
  extends React.HTMLAttributes<HTMLElement> {
  buttonId: JoinButtonIds;
  className?: string;
  isDisabled?: boolean;
  alignment?: TextAlignment;
  title?: string;
}

export const VisitorPageJoinButton: FCWithChildren<
  IVisitorPageJoinButtonProps
> = ({
  buttonId,
  className = null,
  isDisabled,
  alignment = TextAlignment.Left,
  title = null,
  style,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { userType, promptLogin, join, cancelJoinRequest } = useUser();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const { userPaidPlans, eligiblePlans } = useMemberPaidPlans();
  const { buttonState } = useSettingsEvents();
  const {
    selectedPaymentOption,
    startDate,
    isDisableEligiblePlans,
    onJoinValidationError,
    isEmptyStartDateError,
  } = useVisitorPageBase();
  const settings = useSettings();
  const bi = useBi();

  /* --------
    Complex params
    -------- */

  const getResultContent = React.useCallback(() => {
    return getJoinButtonText({
      t,
      userType,
      isAvailableForJoinImmediately: isAvailableForJoinImmediately(challenge),
      joinButtonTextFromSettings:
        title || settings.get(challengeSettings.textJoinButton),
      requestToJoinButtonTextSettings: settings.get(
        challengeSettings.textRequestToJoinButton,
      ),
    });
  }, [userType, title]);
  const getBIButtonName = React.useCallback(() => {
    return getJoinBIButtonName({
      userType,
    });
  }, [userType]);
  const getOnClickHandler = React.useCallback(() => {
    return getJoinClickHandler({
      joinHandler: async () => {
        // Because join request will be doing from the worker, we cant wait here for the result, so just check for the premium by ourselves.
        // if (isParticipantInLockedState) {
        //   setIsPremiumOutdatedModalOpened(true);
        // }

        await join(
          selectedPaymentOption,
          isNeedToSelectStartDate(challenge as any, userType, userPaidPlans)
            ? startDate
            : null,
          {
            showOneAppInfo: settings.get(challengeSettings.displayOneApp),
          },
        );
      },
      cancelHandler: async () => {
        await cancelJoinRequest(challenge.id);
      },
      userType,
    });
  }, [selectedPaymentOption, userType, startDate]);

  /* --------
    Validation
    -------- */

  const validation = React.useCallback(async () => {
    const validationActions = joinValidation({
      buttonId,
      challenge,
      userType,
      startDate,
      userPaidPlans,
      eligiblePlans,
      isDisableEligiblePlans,
    });

    if (validationActions?.length) {
      console.warn('[challenges] Join validation error', validationActions);
      (validationActions || []).forEach((action) => {
        if (action === IJoinValidationAction.SCROLL_TO_FOOTER) {
          // dont see any normal methods to reach the last CTA btn
          scrollToElement('*[data-hook^="challenge-page-cta-button"]', true);
        } else {
          onJoinValidationError(action);
        }
      });

      return;
    }

    if (bi?.report) {
      await bi.report(
        memberWebAppButtonClickV2({
          buttonName: getBIButtonName(),
        }),
      );
    }

    getOnClickHandler()();
  }, [selectedPaymentOption, userType, startDate, isDisableEligiblePlans]);

  /* ---- Render --- */

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          disabled: isDisabled || isEmptyStartDateError,
          alignment,
          buttonState,
        },
        className,
      )}
      style={style}
    >
      <PreviewActionBlockerContainer
        appearance={ActionBlockerAppearance.PopoverAppearance}
      >
        <Button
          disabled={isEmptyStartDateError}
          className={st(classes.button)}
          fullWidth={isMobile}
          data-hook="challenge-page-cta-button"
          onClick={validation}
        >
          {getResultContent()}
        </Button>
      </PreviewActionBlockerContainer>

      {userType === UserState.VISITOR && buttonId === JoinButtonIds.Bottom ? (
        <p className={classes.linkBox}>
          {t('challenge.page.already-a-member')}{' '}
          <a
            href="#"
            className={classes.link}
            onClick={async (e) => {
              e && e.preventDefault();

              await promptLogin();
            }}
          >
            {t('challenge.login')}
          </a>
        </p>
      ) : null}
    </div>
  );
};
