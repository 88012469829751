import { type Step } from '@wix/ambassador-online-programs-v3-step/types';
import { useChallengeData } from '../contexts/storage-contexts/Challenge';
import { getStepType, getVideoOptions, isVideoStep } from '../selectors/step';

export function useVideoStepData(challengeStep: Step) {
  const { challengeData } = useChallengeData();
  const stepType = getStepType(challengeStep);
  const isVideo = isVideoStep(challengeStep);
  const videoOptions = isVideo && getVideoOptions(challengeStep);
  const videoStepDuration = isVideo
    ? videoOptions.durationInMilliseconds
    : null;
  const videoStepsSettings =
    challengeData?.challenge?.settings?.videoStepsSettings;
  const autoNavigate = videoStepsSettings?.autoNavigate;
  const requiredCompletionPercentage =
    videoStepsSettings?.requiredCompletionPercentage;

  return {
    stepType,
    isVideo,
    videoOptions,
    videoStepDuration,
    autoNavigate,
    requiredCompletionPercentage,
  };
}
